<template>
  <view-container name="restore-password">
    <view-content>
      <div data-vue-name="Registration" class="container-fluid registration">
        <div class="auth__title">Restore Password!</div>
        <form class="auth__form" @submit.prevent="handleSubmit">
          <AuthInput
            v-model="user.email"
            @input="showEmailErrorBack = false"
            :valid="isValidMail"
            :placeholder="'Enter your Email'"
            :error="(showEmailErrorFront && !isValidMail) || showEmailErrorBack"
            :errorText="errorEmailMessage"
          />

          <AuthInput
            v-model="user.password"
            @input="showPassErrorBack = false"
            :type="'password'"
            :placeholder="'Enter new Password'"
            :error="(showPassErrorFront && !isValidPass) || showPassErrorBack"
            :errorText="errorPasswordMessage"
          />

          <CommonButton class="submit-button" :type="'submit'" :caption="'Save'" />
        </form>
      </div>
    </view-content>
  </view-container>
</template>

<script>
import { mapState } from 'vuex'
import AuthInput from '@/components/UI/AuthInput'
import CommonButton from '@/components/UI/CommonButton'

export default {
  name: 'RestorePass',
  components: { AuthInput, CommonButton },
  props: ['token'],
  computed: {
    ...mapState({
      errors: ({ auth }) => auth.errors,
      message: ({ auth }) => auth.message,
    }),
    isPasswordType() {
      return this.passwordInputType === 'password'
    },
    colorByType() {
      return this.passwordInputType ? '#D8D8D8' : '#0CEB15'
    },
    isValidMail() {
      return this.validEmail(this.user.email)
    },
    isValidPass() {
      return this.user.password.length >= 6;
    },
    passErrorMsg() {
      return this.errors?.password
    },
    emailErrorMsg() {
      return this.errors?.email
    },
    getEmailErrorText() {
      return this.errors.email?.[0] || this.emailErrorMessageFront || '';
    },
    hasPassError() {
      console.log(this.errors?.password?.[0], this.showPassError, !this.isValidPass);
      return (this.errors?.password?.[0] || this.showPassError) && !this.isValidPass;
    },
    getPassErrorText() {
      return this.errors.password?.[0] || this.passErrorMessageFront || '';
    },
    errorEmailMessage() {
      return this.showEmailErrorFront
        ? this.emailErrorMessageFront
        : this.showEmailErrorBack
          ? this.emailErrorMessageBack
          : '';
    },
    errorPasswordMessage() {
      return this.showPassErrorFront
        ? this.passErrorMessageFront
        : this.showPassErrorBack
          ? this.passErrorMessageBack
          : '';
    }
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      confirmPassword: '',
      passwordInputType: 'password',
      confirmPasswordInputType: 'password',

      showPassErrorFront: false,
      showPassErrorBack: false,
      showEmailErrorFront: false,
      showEmailErrorBack: false,

      passErrorMessageFront: 'Password must contain at least 6 characters',
      passErrorMessageBack: '',
      emailErrorMessageFront: 'Incorrect email address',
      emailErrorMessageBack: '',
    }
  },
  methods: {
    switchVisibilityPassword() {
      this.passwordInputType = this.isPasswordType ? 'text' : 'password'
    },
    handleSubmit() {
      this.showPassErrorFront = false;
      this.showPassErrorBack = false;
      this.showEmailErrorFront = false;
      this.showEmailErrorBack = false;

      if (!this.isValidMail || !this.isValidPass) {
        if (!this.isValidPass) {
          this.showPassErrorFront = true;
        }
        if (!this.isValidMail) {
          this.showEmailErrorFront = true;
        }

        return;
      }
      const data = {
        ...this.user,
        token: this.token,
      }
      this.$store.dispatch('resetPassword', data).then(res => {
        this.$router.push({
          name: 'message',
          params: { message: res.message },
        })
        setTimeout(() => this.$router.push({ name: 'authorization' }), 2000)
      }).catch(error => {
        if ('email' in error.response.data.errors) {
          this.showEmailErrorBack = true;
          this.emailErrorMessageBack = error.response.data.errors.email[0];
        }
        if ('password' in error.response.data.errors) {
          this.showPassErrorBack = true;
          this.passErrorMessageBack = error.response.data.errors.password[0];
        }
      })
    },

    handleGoogleSubmit() {
      this.$store.dispatch('loginWithGoogle').then(res => {
        window.location = res.redirect
      })
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  },
}
</script>

<style lang="stylus">
.restore-password-view {
  .view-content {
    padding 0 !important
  }
}

.danger {
  display block
  color red !important
  margin -7px 0 5px 0
  text-align center
}

.registration {
  height 100%
  width 100%
  background url("../assets/img/bg-page-registr.png") no-repeat
  background-size cover
  display flex
  flex-direction column
}

.registration__title {
  font-family: 'Poppins', sans-serif;
  font-size 36px
  font-weight: 600;
  line-height: 122%;
  color: #D8D8D8;
  margin-bottom 16px
}

.registration__form {
  width 100%
  margin-bottom 72px
}

.registration__form > div {
  position relative
}

.registration__form i {
  position absolute
  z-index 10
  cursor: pointer;
  width 22px
  height 13px
  right 18px
  top 15px
}

.registration__form input {
  width 100%
  background: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  margin-bottom 16px
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #D8D8D8 !important
  padding 13px 35px 13px 16px
}

.registration__form input::placeholder {
  color: #7B8B97;
}

.registration__form .btn-style1 {
  height 56px
  margin-top 8px
  margin-bottom 24px
}

.registration__btn-style-link {
  color: #6293FF;
  margin-top 4px
  text-decoration none
}

.registration__other-account {
  margin-bottom 75px
}

.registration__other-account > div {
  margin-bottom 13px
}

.registration__other-account button {
  background: #D8D8D8;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content center
  margin-bottom 16px
  width 100%
  height 40px
  font-family: 'Roboto', sans-serif

  svg {
    margin-right 24px
  }
}

.submit-button {
  margin: 40px 0 16px;
}
</style>
